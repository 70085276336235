export const t27 = [
  {
    id: 0,
    name: "Vaibhav",
    position: "Technical Member",
    alt: "Vaibhav",
    image: "Team/t27/Vaibhav.jpeg",
    socials: {
      github: "https://github.com/vaibhavtadivaka",
      instagram: "https://www.instagram.com/nagasaivaibhav",
      linkedin: "https://www.linkedin.com/in/naga-sai-vaibhav-tadivaka-a901882a4/",
    },
  },
  {
    id: 1,
    name: "Pranadeep Reddy",
    position: "Technical Member",
    alt: "Pranadeep Reddy",
    image: "Team/t27/Pranadeep.jpeg",
    socials: {
      github: "https://github.com/pranadeep1",
      instagram: "https://www.instagram.com/pranadeepreddy",
      linkedin: "https://www.linkedin.com/in/pranadeep-reddy-943128284",
    },
  },
  {
    id: 2,
    name: "GVNSS.Vignesh",
    position: "Technical Member",
    alt: "Vignesh",
    image: "Team/t27/Vignesh.jpeg",
    socials: {
      github: "https://github.com/Vignesh777777",
      instagram: "https://www.instagram.com/gvnss_vignesh",
      linkedin: "https://www.linkedin.com/in/vignesh-gazula-211539293",
    },
  },
  {
    id: 3,
    name: "Vedansh Sharma",
    position: "Technical Member",
    alt: "Vedansh Sharma",
    image: "Team/t27/Vedansh.jpeg",
    socials: {
      github: "https://github.com/VedanshSharma53",
      instagram: "https://www.instagram.com/vedansh_sharmaa__",
      linkedin: "https://www.linkedin.com/in/vedansh-sharmaa",
    },
  },
  {
    id: 4,
    name: "Satwik Raj",
    position: "Technical Member",
    alt: "Geethika",
    image: "Team/t27/Sathwik Raj.jpeg",
    socials: {
      github: "https://github.com/vajjipartisatwikraj",
      instagram: "https://www.instagram.com/vajjiparti_satwikraj",
      linkedin: "https://www.linkedin.com/in/satwik-raj-vajjiparti-24b2152a4",
    },
  },
  {
    id: 5,
    name: "Ashutosh",
    position: "Technical Member",
    alt: "Ashutosh",
    image: "Team/t27/Ashutosh.jpg",
    socials: {
      github: "https://github.com/Ashutosh3678",
      instagram: "https://www.instagram.com/mr_ash1805?igsh=MjRlaHZtdXJ2dHVp",
      linkedin:
        "https://www.linkedin.com/in/%C3%A3shutosh-si%C3%B1gh-5b363220a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  },
  {
    id: 6,
    name: "Assis Mohanty",
    position: "Technical Member",
    alt: "Assis Mohanty",
    image: "Team/t27/Assis.jpg",
    socials: {
      github: "https://github.com/Assis-Mohanty",
      instagram: "",
      linkedin: "https://www.linkedin.com/in/assis-mohanty-668269238/",
    },
  },
  {
    id: 7,
    name: "Geethika",
    position: "Graphic Designer",
    alt: "Geethika",
    image: "Team/t27/Geethika.jpeg",
    socials: {
      github: "https://github.com/Geethikaaa12",
      instagram: "https://www.instagram.com/geethikaaa_g",
      linkedin: "https://www.linkedin.com/in/geethika-g-3172142a4",
    },
  },
  {
    id: 8,
    name: "Venila",
    position: "Graphic Designer",
    alt: "Venila",
    image: "Team/t27/Venila.jpeg",
    socials: {
      github: "https://github.com/VENILABADUGU012",
      instagram: "https://www.instagram.com/tomriddle09876",
      linkedin: "https://www.linkedin.com/in/venila-badugu15",
    },
  },
  {
    id: 9,
    name: "Jai Bharath Reddy",
    position: "Graphic Designer",
    alt: "Jai Bharath Reddy",
    image: "Team/t27/Bharath.jpg",
    socials: {
      github: "https://github.com/jbreddy1",
      instagram:
        "https://www.instagram.com/.jaibharath.?igsh=MWVneHM1bGU0em13ag==",
      linkedin:
        "https://www.linkedin.com/in/jai-bharath-reddy-rajula-8262142a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",

    },
  },
  {
    id: 10,
    name: "Meghana",
    position: "Graphic Designer",
    alt: "Meghana",
    image: "Team/t27/Meghana.jpg",
    socials: {
      github: "https://github.com/Kmeghana2006",
      instagram: "https://www.instagram.com/meghaaa_3_?igsh=YW1xamZ6ejNxODUy",
      linkedin: "https://www.linkedin.com/in/k-meghana-605247324?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  },
  {
    id: 11,
    name: "Karthik Reddy",
    position: "Content Writer",
    alt: "Karthik Reddy",
    image: "Team/t27/Karthik Reddy.jpeg",
    socials: {
      github: "https://github.com/karthikreddynyalapatla",
      instagram: "https://www.instagram.com/karthikreddyn._",
      linkedin: "https://www.linkedin.com/in/karthik-reddy-nyalapatla-0833172b1",
    },
  },
  {
    id: 12,
    name: "Harshith Reddy",
    position: "Promotions",
    alt: "Harshith Reddy",
    image: "Team/t27/Harshith.jpeg",
    socials: {
      github: "https://github.com/Harshithgreek",
      instagram: "https://www.instagram.com/mr_h_a_r_s_h_a_1",
      linkedin: "https://www.linkedin.com/in/harshith-reddy-bandla-28b2942b6",
    },
  },
  {
    id: 13,
    name: "Neha Bingi",
    position: "Promotions",
    alt: "Neha Bingi",
    image: "Team/t27/Neha.jpeg",
    socials: {
      github: "https://github.com/neha-bingi",
      instagram: "https://www.instagram.com/neha_bingi",
      linkedin: "https://www.linkedin.com/in/neha-bingi-010432288",
    },
  },
  {
    id: 14,
    name: "Pravallika",
    position: "Promotions",
    alt: "Pravallika",
    image: "Team/t27/Pravallika.jpeg",
    socials: {
      github: "https://github.com/J-Pravalika",
      instagram: "https://www.instagram.com/_pravalika.j_",
      linkedin: "https://www.linkedin.com/in/pravalika-j-169990320",
    },
  },
  {
    id: 15,
    name: "Priya",
    position: "Operations",
    alt: "Priya",
    image: "Team/t27/Priya.jpeg",
    socials: {
      github: "https://github.com/priyaa-0605",
      instagram: "https://www.instagram.com/priyaa_0605",
      linkedin: "https://www.linkedin.com/in/priyaa-veerabathini-81623a2b7",
    },
  },
  {
    id: 16,
    name: "Yogitha",
    position: "Operations",
    alt: "Yogitha",
    image: "Team/t27/Yogitha.jpeg",
    socials: {
      github: "https://github.com/yogithasai",
      instagram: "https://www.instagram.com/yogithasai",
      linkedin: "https://www.linkedin.com/in/yogitha-sai-77a0a2288",
    },
  },
  {
    id: 18,
    name: "Preetham",
    position: "Video Editing",
    alt: "Preetham",
    image: "Team/t27/Preetham.jpg",
    socials: {
      github: "",
      instagram: "https://www.instagram.com/preethamx5105/",
      linkedin: "https://www.linkedin.com/in/preetham-p-210564348?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ",
    },
  },
];
