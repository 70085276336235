export const t26 = [
  {
    id: 0,
    name: "Tej Pavan",
    position: "President",
    alt: "Tej Pavan",
    image: "Team/t26/tejpavan.jpg",
    socials: {
      github: "",
      instagram: "",
      linkedin: "",
    },
  },
  {
    id: 1,
    name: "Pendyala Kavya",
    position: "Vice President",
    alt: "Pendyala Kavya",
    image: "Team/t26/kavya.jpg",
    socials: {
      github: "https://github.com/pendyalakavya18",
      instagram: "",
      linkedin: "https://www.linkedin.com/in/pendyala-kavya-266ba3292",
    },
  },
  {
    id: 2,
    name: "K Indra Koushik",
    position: "Treasurer",
    alt: "K Indra Koushik",
    image: "Team/t26/koushik.jpg",
    socials: {
      github: "https://github.com/K-Indra-Koushik/K-Indra-Koushik.git",
      instagram: "https://www.instagram.com/___leo_roar_________/",
      linkedin: "https://www.linkedin.com/in/k-indra-koushik-2b26a4294/",
    },
  },
  {
    id: 3,
    name: "Asreetha Guvvala",
    position: "Secretary",
    alt: "Asreetha Guvvala",
    image: "Team/t26/asreetha.jpg",
    socials: {
      github: "",
      instagram: "",
      linkedin: "",
    },
  },
  {
    id: 4,
    name: "Samala Sripadh",
    position: "Technical Lead",
    alt: "Samala Sripadh",
    image: "Team/t26/sripadh.jpg",
    socials: {
      linkedin: "https://in.linkedin.com/in/samala-sripadh-17aa50294",
      github: "",
      instagram: "",
    },
  },
  {
    id: 5,
    name: "Harshitha Yadav",
    position: "Operations Lead",
    alt: "Harshitha Yadav",
    image: "Team/t26/harshitha.jpg",
    socials: {
      github: "https://github.com/Possiblef",
      instagram: "https://instagram.com/hershey_yadav18?igshid=MTk0NTkyODZkYg==",
      linkedin: "https://www.linkedin.com/in/harshitha-yadav-12a085293",
    },
  },
  {
    id: 7,
    name: "Dhanush Reddy",
    position: "Promotions Lead",
    alt: "Dhanush Reddy",
    image: "Team/t26/dhanush.jpg",
    socials: {
      github: "",
      instagram: "https://www.instagram.com/invites/contact/?i=fxgxwtjkclqy&utm_content=r6awvgg",
      linkedin: "https://www.linkedin.com/in/dhanush-reddy-ba729725b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  },
  {
    id: 8,
    name: "Aravind Reddy",
    position: "Photography Lead",
    alt: "Aravind Reddy",
    image: "Team/t26/aravind.jpg",
    socials: {
      github: "https://github.com/aravindreddy540",
      instagram: "https://instagram.com/aravindreddy5406?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr",
      linkedin: "https://www.linkedin.com/in/aravind-reddygari-37051028a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
  },
  {
    id: 9,
    name: "K. Joseph",
    position: "Graphic Designing Lead",
    alt: "K. Joseph",
    image: "Team/t26/jason.jpg",
    socials: {
      github: "https://github.com/jasonsama44",
      instagram: "",
      linkedin: "",
    },
  },
  {
    id: 10,
    name: "Gannaram Prasanna",
    position: "Technical Member",
    alt: "Gannaram Prasanna",
    image: "Team/t26/prasanna.jpg",
    socials: {
      github: "",
      instagram: "",
      linkedin: "",
    },
  },
  {
    id: 11,
    name: "K.Anusha",
    position: "Promotions",
    alt: "K.Anusha",
    image: "Team/t26/anusha.jpg",
    socials: {
      github: "",
      instagram: "",
      linkedin: "",
    },
  },
  {
    id: 12,
    name: "M Harshavardhan",
    position: "Technical Member",
    alt: "M Harshavardhan",
    image: "Team/t26/harsha.jpeg",
    socials: {
      github: "https://github.com/Harsha19-08",
      instagram: "https://instagram.com/curious_harsha?igshid=MTk0NTkyODZkYg==",
      linkedin: "https://www.linkedin.com/in/harsh199?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  },
  {
    id: 13,
    name: "N Aasrith",
    position: "Technical Member",
    alt: "N Aasrith",
    image: "Team/t26/aashrith.jpg",
    socials: {
      github: "https://github.com/Aasrith22",
      instagram: "",
      linkedin: "",
    },
  },
  {
    id: 14,
    name: "Nitish Kumar Yadav",
    position: "Technical Member",
    alt: "Nitish Kumar Yadav",
    image: "Team/t26/nithish.jpg",
    socials: {
      github: "https://github.com/Nitish-Appireddypally",
      instagram: "https://www.instagram.com/_nitish_yadav.__/",
      linkedin: "https://www.linkedin.com/in/nitish-appireddypally-869737295/",
    },
  },
  {
    id: 15,
    name: "P Sandeep Chowdary",
    position: "Technical Member",
    alt: "P Sandeep Chowdary",
    image: "Team/t26/sandeep.jpg",
    socials: {
      github: "",
      instagram: "",
      linkedin: "https://www.linkedin.com/in/polina-sandeep-chowdary-66aa91292?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  },
  {
    id: 16,
    name: "Sravya Lingisetty",
    position: "Promotions",
    alt: "Sravya Lingisetty",
    image: "Team/t26/sravya.jpg",
    socials: {
      github: "",
      instagram: "",
      linkedin: "",
    },
  },
  {
    id: 17,
    name: "Besta Dinesh",
    position: "Technical Member",
    alt: "Dinesh",
    image:"Team/t26/dinesh.jpg",
    socials:{
      github: "",
      instagram: "",
      linkedin: "",
    }
  }


];
