export const bod = [
  {
    id: 0,
    name: "Dr.N.V.RAJASEKHAR REDDY",
    position: "Club Head,HOD-IT, HOD- Trainings",
    alt: "rajsekhar sir",
    image: "./Team/Bd/raj.webp",
    socials: {
      linkedin: "https://www.linkedin.com/in/rajasekharreddynv/",
      github: '',
      instagram:'',
    },
  },
];




